import './bootstrap';
import '../css/app.css';

import Vapor from 'laravel-vapor';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import * as Sentry from "@sentry/vue";
import {format} from "date-fns";
import type {Vue} from "@sentry/vue/types/types";
import {createPinia} from "pinia";

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)
window.Vapor = Vapor;
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .mixin({
                methods: {
                    uploadedAsset: (path: string) => "https://" + import.meta.env.VITE_AWS_USER_UPLOADED_DNS_URL + "/" + path,
                    asset: Vapor.asset,
                    openInNewTab: (href: string) => {
                        Object.assign(document.createElement("a"), {
                            target: "_blank",
                            rel: "noopener noreferrer",
                            href: href,
                        }).click();
                    },
                    formatDate: (isoStringDate: string) => {
                        const date = new Date(isoStringDate);
                        return format(date, 'MMMM do, yyyy');
                    }
                },
            });

        setupSentry(app);

        const pinia = createPinia();
        // pinia.use(resetStore);
        app.use(pinia);

        return app.mount(el);
    },
    progress: {
        color: '#0891b2',
    },
});

function setupSentry(app: Vue) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
        integrations: [
            // new Sentry.BrowserTracing({
            //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            // }),
            new Sentry.Replay(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
